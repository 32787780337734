#sidenav-block {
  position: fixed;
  display: block;
  margin-left: 200px;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  background-color: #606060 !important;
}
.sc-ifAKCX .iAgGBH .sidenav-navitem---3r9ER .selected---91VwC .highlighted---1vG8Y {
  background-color: #cc2000;
}

.sidenav---expanded .sidenav---sidenav-navitem-- :hover {
  background-color: #cc2000;
}
