/** @format */

@font-face {
  font-family: "NotoSansLao";
  src: local("NotoSansLao"),
    url(./fonts/NotoSansLao/NotoSansLaoUI-Regular.ttf) format("truetype");
}
*,
body,
.Dialogbox,
div {
  margin: 0;
  font-family: "NotoSansLao";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 15px; */
}
/* defual color thids project */
/* ============ */
.btn-app {
  background-color: #816aae !important;
  color: #fff;
  /* padding: 10px; */
}
/* poum lob poum kae khai mee tae sen pen see background see khao */
.app-button{
background-Color: "#ffff";
border: solid 1px #816aae;
color: #816aae
}
/* ================= */
.button-white {
  border: solid 1px #606060;
}
.wrap-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  padding-left: 5px !important;
}
.table {
  border: none;
}
.fa:focus,
button:focus {
  outline: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fa-2x {
  font-size: 20px !important;
}
.btn-hov:hover {
  background-color: #c8c8c9;
  border: 1px solid #c8c8c9;
  color: #fff;
}
.decoration-hide {
  text-decoration: none !important;
}
.center {
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

td {
  vertical-align: middle !important;
  cursor: pointer !important;
}
.space-1 {
  display: inline-block;
  width: 1px;
}
.space-2 {
  display: inline-block;
  width: 2px;
}
.space-4 {
  display: inline-block;
  width: 4px;
}
.space-5 {
  display: inline-block;
  width: 5px;
}
.space-6 {
  display: inline-block;
  width: 6px;
}
.space-7 {
  display: inline-block;
  width: 7px;
}
.space-8 {
  display: inline-block;
  width: 8px;
}
.space-9 {
  display: inline-block;
  width: 9px;
}
.space-10 {
  display: inline-block;
  width: 10px;
}
.space-20 {
  display: inline-flex;
  width: 20px;
}
.space-100 {
  display: inline-flex;
  width: 100px;
}
.vertic-1 {
  display: block;
  height: 1vh;
}
.vertic-2 {
  display: block;
  height: 2vh;
}
.vertic-3 {
  display: block;
  height: 3vh;
}
.vertic-4 {
  display: block;
  height: 4vh;
}
.vertic-5 {
  display: block;
  height: 5vh;
}
.vertic-10 {
  display: block;
  height: 10vh;
}
.vertic-20 {
  display: block;
  height: 20vh;
}
.left {
  display: inline-flex;
  float: left !important;
}
.right {
  display: inline-flex;
  float: right !important;
}
.heading {
  display: block;
  background-color: #f1f1f1;
  padding-top: 7px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.btn-md {
  width: 160px;
  justify-content: center;
}
.point {
  display: block;
  border: 1px solid #f1f1f1;
  padding: 5px;
  width: 100px;
  border-radius: 20px;
  text-align: center;
}
.point-win {
  background-color: #ccedd1;
}
.point-lost {
  background-color: #f5cccc;
}
.Onhover:hover {
  background-color: #f1f1f1;
}
.flex-inline {
  display: block;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.image-item {
  display: inline;
  width: 130px;
  height: 130px;
  margin: 15px;
  border: 2px double #f1f1f1;
  border-radius: 4px;
}
Label {
  margin-top: 10px;
  margin-bottom: -2px;
}


.limitText{
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination .page-item.active .page-link{
  background-color: #816AAE;
  border-color: #816AAE;
}

.pagination .page-link {
  color: #000;
}

